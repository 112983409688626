.intro {
  padding-top: 30%;
  padding-left: 20px;
}

p {
  margin-top: 10px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-content {
  display: grid;
  padding-right: 10%;
  padding-left: 10%;
  background-color: #edf7fa;
  padding-bottom: 30px;
  grid-template-columns: 6fr 6fr;
}

#home-post-det {
  margin-top: 10px;
}

#profile {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.home-posts-list {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin-top: 20px;
  padding-bottom: 30px;
  grid-gap: 20px;
}
.post-info {
  display: flex;
  flex-direction: row;
}

.home-post-item {
  cursor: pointer;
  transition: all 0.5s ease;
}

.tags {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  color: #8695a4;
}


.view-proj{
  border: 2px solid white;
  color: white;
}

.view-proj:hover{
  background-color: white;
  color: #192848;
  transition: all 0.5s ease;
}

.post-info span {
  margin-right: 20px;
}
.skill-item {
  display: flex;
  padding: 20px;
  margin-right: 15px;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding-bottom: 40px !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.titles {
  font-size: 1.3rem;
}

.skill-item label {
  margin-bottom: 10px;
}

.skill-item progress {
  width: 100%;
  border-radius: 100px;
  height: 10px;
  border-radius: 20px;
  color: #061127;
}

progress::-webkit-progress-bar {
  border: 0;
  height: 20px;
  border: 2px solid #061127;
  background-color: white;
  border-radius: 20px;
}
progress::-webkit-progress-value {
  border: 0;
  height: 20px;
  border-radius: 20px;
  background-color: #061127;
}

progress::-webkit-progress-value::before {
  content: "68%";
  z-index: 10;
}

progress::-moz-progress-bar {
  border: 0;
  height: 20px;
  background-color: white;
  background-color: rgb(0, 160, 252);
  border-radius: 20px;
}

.posts {
  padding-right: 10%;
  padding-top: 3%;
  display: none;
  padding-left: 10%;
  padding-bottom: 3%;
  background-color: #edf7fa;
}
h1 {
  font-size: 2.8rem;
}
.skills {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 30px;
  background-color: white;
  padding-top: 30px;
}

h3 {
  font-size: 20px;
  font-family: Verdana, sans-serif;
  font-weight: bold;
  color: #fff;
}

.work-item{
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    height: 50vh;
}
.work-item-div{
    height: 70%;
}

.work-item-img{
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    object-fit: cover;
    border-radius: 10px;
}
.works {
    background-color: #08193c;
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 30px;
    color: white;
    padding-top: 30px;
  }
  
  #input-message{
   
    padding: 10px 10px 200px 10px;
  }
  .home-works {
    color: white;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }

  .work-item-div{
    overflow: hidden;
    border-radius: 18px;
  }
.work-item-img:hover{
    transform: scale(1.3);
}

.footer{
    background-color: white;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer div{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.footer-icon{
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.footer-icon:hover{
    transform: scale(1.2);
}

.github{
    filter: opacity(1) drop-shadow(0 0 0 #060b31);
}
.contact{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #e7e7e7;
}
.input-div{
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.input-div span{
    margin-bottom: 6px;
    font-size: 20px;
    color: #061127;
    font-weight: bold;
}

.input-div input, textarea{
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #061127;
}

form .button{
    margin-top: 20px;
    margin-left: 7px;
}
.contactor-det{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.brisha{
    font-size: 20px;
    color: #061127;
    font-weight: bold;
    margin-left: 10px;

}



@media only screen and (max-width: 600px){

    .home-content{
        display: flex;
        flex-direction: column-reverse;
        padding-right: 10%;
        padding-left: 10%;
        padding-bottom: 30px;

    }
    .skills>div{
        display: flex;
        margin-top: 0 !important;
        flex-direction: column !important;
    }
    .skill-item{
        margin-top: 20px;
    }
    .home-works{
        display: flex;
        flex-direction: column;
    }

    .contactor-det{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .intro{
        padding-top: 5%;
    }
    .intro>h1{
        font-size: 2rem;
    }
}