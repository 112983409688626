*{
  margin: 0;
  padding: 0;
}
body{
  padding: 0px;
}
#root{
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}
.card{
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.zoom-anim-parent{
  overflow: hidden;
}

.zoom-anim-parent img:hover{
  transform: scale(1.3);
}
.zoom-anim-parent img{
  transition: all 0.5s ease;
}

.btn-text:hover{
  color: #061127;
}

.btn-text{
  color: black;
  cursor: pointer;
  transition: all 0.5s ease;
}

.button{
  background-color: transparent;
  border: none;
  color: #061127;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 2px solid #061127;
}

button:hover{
  background-color: #061127;
  color: white;
  transition: all 0.7s ease;
}

.f-right{
  position: absolute;
  right: 10%;

}
.main{
  display: flex;
  flex-direction: column;
}

