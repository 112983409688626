.main-p{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
}
.project-images{
    display: grid;
    width: 100%;
    grid-template-columns: 6fr 6fr;
    margin-top: 20px;
    grid-gap: 30px;
}
.project-images img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
}




/* mediaquery */

@media only screen and (max-width: 600px) {
    .project-images{
        display: grid;
        grid-template-columns: 12fr;
        margin-top: 20px;
        grid-gap: 30px;
    }
    .project-images img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
    }
}