div {
}

.nav {
  padding: 14px;
  justify-content: flex-end;
  background-color: #edf7fa;
}
.nav ul {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.nav-icon {
  height: 20px;
  width: 20px;
  object-fit: cover;
  margin-right: 7px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav ul li {
  list-style: none;
  margin: 0 10px;
  /* mouse type pointer */
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

/* hover */
.nav ul li:hover {
  box-shadow: #4d4d4d 5px 0px 10px;
  background-color: #061127;
  color: white;
  border-radius: 18px;
  /* border-bottom: 5px solid #f00; */
}

.nav ul a{
    text-decoration: none;
    color: #061127;
    
}

/* mediaquery */

@media only screen and (max-width: 600px) {
  .nav ul {
    width: 100%;
    justify-content: start;
  }
}
